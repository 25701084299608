import React, { useEffect, ChangeEvent, useState } from 'react';
import { DataGrid, GridColumns } from '@material-ui/data-grid';

import { toast } from 'react-toastify';
import { MdDeleteForever } from 'react-icons/md';
import { GoPencil, GoPackage } from 'react-icons/go';

import {
  Col,
  OverlayTrigger,
  Tooltip as TooltipBootstrap,
  Row,
} from 'react-bootstrap';
import { Radio } from '@atlaskit/radio';
import { Checkbox } from '@atlaskit/checkbox';
import { ButtonRow } from '../../styles';
import ToggleDefault from '~/components/ToggleDefault';
import Separator from '~/components/Separator';

import { useProduto } from '~/pages/Produto/ProdutoContext';

import { ClearButton, ConfirmButton } from '~/components/Buttons';

import {
  FormProdutoFornecedor,
  formProdutoFornecedorBlank,
  FormProdutoFornecedorUN,
} from '~/pages/Produto/protocols';
import { AcoesContainer, TableContainer } from './styles';
import { nanoid } from 'nanoid';
import {
  InputText,
  InputSelect,
  InputNumber,
  BuscaParceiro,
} from '~/components/NovosInputs';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import Tooltip from '@atlaskit/tooltip';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const AbaFornecedor: React.FC = () => {
  const {
    formProduto,
    changeFormProduto,
    produtoFornecedoresUns,
    setProdutoFornecedorUns,
    selectedCodFornecedor,
    setSelectedCodFornecedor,
    register,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    setInitFormFornecedor,
    setInitFormFornecedorUn,
    produtoFornecedores,
    setProdutoFornecedores,
    formProdFornUN,
    setFormProdFornUN,
    formProdutoFornecedor,
    setFormProdutoFornecedor,
    idProdUnid,
    setIdProdUnid,
    handleClearProdFornUN,
    handleClearProdutoFornecedor,
    resetFormEmbalagem,
    flgFabricante,
    setFlgFabricante,
    disabledField,
    setDisabledField,
    setFormProdutoFornecedorEmbalagem,
    formProdutoFornecedorEmbalagem,
    unidadesOptions,
  } = useProduto();

  const MySwal = withReactContent(Swal);

  const [updateFornecedor, setUpdateFornecedor] = useState<boolean>(false);

  useEffect(() => {
    const uns = formProdutoFornecedor.Unidades.unidades.map((unidade) => {
      return {
        id: nanoid(),
        des_fornecedor: formProdutoFornecedor.des_fornecedor.value,
        des_referencia: unidade.des_referencia.value,
        des_unidade_compra: unidade.des_unidade_compra.value.value,
        qtd_embalagem_compra: unidade.qtd_embalagem_compra.value,
      };
    });
    setProdutoFornecedorUns(uns);
  }, [formProdutoFornecedor.Unidades.unidades]);

  useEffect(() => {
    const regsProdutoFornecedores = formProduto.Fornecedores.fornecedores.map(
      (item) => {
        return {
          id: item.cod_fornecedor.value,
          cod_fornecedor: item.cod_fornecedor.value || undefined,
          num_cpf_cnpj: item?.num_cpf_cnpj?.value?.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            '$1.$2.$3/$4-$5',
          ),
          des_fornecedor: item.des_fornecedor.value,
          flg_principal: item.flg_principal.value,
          flg_fabricante: item.flg_fabricante.value,
          des_referencia: '',
          unidades: item.Unidades.unidades,
        };
      },
    );

    setProdutoFornecedores(regsProdutoFornecedores);
  }, [formProduto.Fornecedores.fornecedores]);

  const unidadeColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'id',
      width: 180,
      hide: true,
      disableColumnMenu: true,
    },
    {
      field: 'des_unidade_compra',
      headerName: 'UN Compra',
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: 'qtd_embalagem_compra',
      headerName: 'Quant. Compra',
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: 'des_referencia',
      headerName: 'Referência',
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: 'Ações',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      width: 220,
      flex: 1,
      minWidth: 180,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onEdit = () => {
          setIdProdUnid(row.id);

          const regForn = formProduto.Fornecedores.fornecedores.find(
            (item: any) => {
              return (
                Number(item.cod_fornecedor.value) ===
                Number(selectedCodFornecedor)
              );
            },
          );
          const reg = regForn?.Unidades.unidades.find((item: any) => {
            return item.des_referencia.value === row.des_referencia;
          });
          if (reg) {
            setValue('des_referencia', row.des_referencia);
            setValue(
              'des_unidade_compra_fornecedor',
              unidadesOptions.find(
                (item: any) => row.des_unidade_compra === item.value,
              ),
            );
            setValue(
              'qtd_embalagem_compra_fornecedor',
              row.qtd_embalagem_compra,
            );
            reg.id = {
              value: row.id,
              isInvalid: false,
              isRequired: false,
            };
            setFormProdFornUN(reg);
          }
          setTimeout(() => {
            handleSetFocus();
          }, 200);
        };
        const onDelete = () => {
          const produtoFornecedoresUnsFilter = produtoFornecedoresUns.filter(
            (un) => {
              return un.id !== row.id;
            },
          );

          setProdutoFornecedorUns(produtoFornecedoresUnsFilter);

          const filtered = formProduto.Fornecedores.fornecedores.map((item) => {
            if (
              Number(item.cod_fornecedor.value) ===
              Number(selectedCodFornecedor)
            ) {
              const uns = item.Unidades.unidades.filter((un) => {
                return (
                  un.des_referencia.value !== row.des_referencia ||
                  un.des_unidade_compra.value.value !==
                    row.des_unidade_compra ||
                  un.qtd_embalagem_compra.value !== row.qtd_embalagem_compra
                );
              });
              item.Unidades.unidades = uns;
            }
            return item;
          });
          setValue('fornecedores', filtered);
          resetFormEmbalagem();
          handleClearProdFornUN();

          changeFormProduto({
            ...formProduto,
            Fornecedores: {
              ...formProduto.Fornecedores,
              fornecedores: filtered,
            },
          });
        };

        return (
          <AcoesContainer>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </AcoesContainer>
        );
      },
    },
  ];

  function validaFormProdutoFornecedor(inputs: FormProdutoFornecedor): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formProdutoFornecedor[
          Object.keys(inputs)[i] as keyof FormProdutoFornecedor
        ]?.isRequired &&
        formProdutoFornecedor[
          Object.keys(inputs)[i] as keyof FormProdutoFornecedor
        ]?.isInvalid
      ) {
        return true;
      }
    }
    return false;
  }

  function handleAddProdutoFornecedor() {
    if (validaFormProdutoFornecedor(formProdutoFornecedor)) {
      if (formProdutoFornecedor.cod_fornecedor) {
        setError('busca_parceiro', { type: 'error' });
      }

      setInitFormFornecedor(true);
      return;
    }
    setDisabledField(false);
    setInitFormFornecedor(false);

    const hasExists = formProduto.Fornecedores.fornecedores.find(
      (item: any) => {
        return (
          Number(item.cod_fornecedor.value) ===
          Number(formProdutoFornecedor.cod_fornecedor.value)
        );
      },
    );

    if (hasExists) {
      if (updateFornecedor) {
        const newFornecedores = formProduto.Fornecedores.fornecedores.map(
          (item) => {
            if (
              Number(item.cod_fornecedor.value) ===
              Number(formProdutoFornecedor.cod_fornecedor.value)
            ) {
              item = {
                ...formProdutoFornecedor,
                flg_fabricante: {
                  ...formProdutoFornecedor.flg_principal,
                  value: formProdutoFornecedor.flg_fabricante.value,
                },
                flg_principal: {
                  ...formProdutoFornecedor.flg_principal,
                  value: hasExists.flg_principal.value,
                },
                Unidades: {
                  ...formProdutoFornecedor.Unidades,
                  unidades: hasExists.Unidades.unidades,
                },
              };
            }
            return item;
          },
        );
        changeFormProduto({
          ...formProduto,
          Fornecedores: {
            ...formProduto.Fornecedores,
            fornecedores: newFornecedores,
          },
        });
        setUpdateFornecedor(false);
        setValue('fornecedores', [...newFornecedores]);
        handleClearProdutoFornecedor();
      } else {
        setUpdateFornecedor(false);
        toast.warning(
          'Fornecedor já relacionado na lista de fornecedores deste produto.',
        );
      }
    } else {
      if (formProduto.Fornecedores.fornecedores.length === 0) {
        const reg: FormProdutoFornecedor = {
          ...formProdutoFornecedor,
          flg_principal: {
            ...formProdutoFornecedor.flg_principal,
            value: true,
          },
          Unidades: {
            ...formProdutoFornecedor.Unidades,
            unidades: [],
          },
        };
        handleClearProdutoFornecedor();
        setValue('fornecedores', [
          ...formProduto.Fornecedores.fornecedores,
          reg,
        ]);
        changeFormProduto({
          ...formProduto,
          Fornecedores: {
            ...formProduto.Fornecedores,
            fornecedores: [...formProduto.Fornecedores.fornecedores, reg],
          },
        });
        setUpdateFornecedor(false);
        return;
      }

      formProdutoFornecedor.flg_principal.value = false;
      setValue('fornecedores', [
        ...formProduto.Fornecedores.fornecedores,
        formProdutoFornecedor,
      ]);
      setUpdateFornecedor(false);
      changeFormProduto({
        ...formProduto,
        Fornecedores: {
          ...formProduto.Fornecedores,
          fornecedores: [
            ...formProduto.Fornecedores.fornecedores,
            formProdutoFornecedor,
          ],
        },
      });
      handleClearProdutoFornecedor();
    }
  }

  const handleSetFocus = () => {
    const referenciaElement = document.getElementById('des_referencia');
    if (referenciaElement) {
      referenciaElement.focus();
      referenciaElement.click();
    }
  };

  const produtoFornecedorColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: 'num_cpf_cnpj',
      headerName: 'CNPJ',
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: 'des_fornecedor',
      headerName: 'Descrição',
      width: 280,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <Tooltip content={row.des_fornecedor}>
            <div
              className="text-truncate"
              style={{
                position: 'relative',
                width: '200px',
              }}
            >
              <span> {row.des_fornecedor}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'flg_principal',
      headerName: 'Preferencial',
      headerAlign: 'center',
      minWidth: 130,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: { row: any }) => {
        const { row } = params;

        const handleChangePrincipal = () => {
          const { cod_fornecedor } = row;

          const newData = formProduto.Fornecedores.fornecedores.map((item) => {
            if (item.cod_fornecedor.value === cod_fornecedor) {
              return {
                ...item,
                flg_principal: {
                  ...item.flg_principal,
                  value: true,
                },
              };
            }
            return {
              ...item,
              flg_principal: {
                ...item.flg_principal,
                value: false,
              },
            };
          });

          changeFormProduto({
            ...formProduto,
            Fornecedores: {
              ...formProduto.Fornecedores,
              fornecedores: newData,
            },
          });
          setValue('fornecedores', [...newData]);
        };

        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Radio
                value="default radio"
                name="radio-default"
                testId="radio-default"
                isChecked={row.flg_principal}
                onChange={handleChangePrincipal}
              />
            </div>
          </>
        );
      },
    },
    {
      field: 'flg_fabricante',
      headerName: 'Fabricante?',
      headerAlign: 'center',
      width: 120,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: { row: any }) => {
        const { row } = params;

        const handleChangeFabricante = () => {
          const { cod_fornecedor } = row;

          const newData = formProduto.Fornecedores.fornecedores.map((item) => {
            if (item.cod_fornecedor.value === cod_fornecedor) {
              return {
                ...item,
                flg_fabricante: {
                  ...item.flg_fabricante,
                  value: !item.flg_fabricante.value,
                },
              };
            }
            return item;
          });
          changeFormProduto({
            ...formProduto,
            Fornecedores: {
              ...formProduto.Fornecedores,
              fornecedores: newData,
            },
          });
          setValue('fornecedores', [...newData]);
        };

        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Checkbox
                isChecked={row.flg_fabricante}
                onChange={handleChangeFabricante}
              />
            </div>
          </>
        );
      },
    },
    {
      field: 'referencias',
      headerName: 'Referências',
      sortable: false,
      width: 220,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const arrRefs = row.unidades.map((item: any) => {
          return {
            ref: item.des_referencia.value,
            descricao: `${item.des_unidade_compra.value.value} C/${item.qtd_embalagem_compra.value} Ref: (${item.des_referencia.value})`,
          };
        });
        const refs = arrRefs
          .map((item: any) => {
            return item.ref;
          })
          .join(' | ');
        const descricao = arrRefs
          .map((item: any) => {
            return item.descricao;
          })
          .join('\n');
        return (
          <>
            <OverlayTrigger
              placement="bottom"
              overlay={<TooltipBootstrap id="">{descricao}</TooltipBootstrap>}
            >
              <p
                style={{
                  margin: '0',
                }}
              >
                {refs}
              </p>
            </OverlayTrigger>
          </>
        );
      },
    },
    {
      field: '',
      headerName: 'Ações',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 350,
      flex: 1,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onEdit = async (embalagem = false) => {
          const reg = formProduto.Fornecedores.fornecedores.find((item) => {
            return (
              Number(item.cod_fornecedor.value) === Number(row.cod_fornecedor)
            );
          });
          if (reg) {
            const uns = reg.Unidades.unidades.map((unidade: any) => {
              return {
                id: nanoid(),
                des_fornecedor: formProdutoFornecedor.des_fornecedor.value,
                des_referencia: unidade.des_referencia.value,
                des_unidade_compra: unidade.des_unidade_compra.value.value,
                qtd_embalagem_compra: unidade.qtd_embalagem_compra.value,
              };
            });
            setProdutoFornecedorUns(uns);

            reg.cod_fornecedor.ref = row.cod_fornecedor;
            if (reg.num_cpf_cnpj.value) {
              reg.num_cpf_cnpj.isInvalid = false;
            }
            setFormProdutoFornecedorEmbalagem(reg);
          }

          if (!embalagem) {
            setValue('busca_parceiro', {
              label: row.des_fornecedor,
              value: row.cod_fornecedor,
            });

            setDisabledField(true);
            setUpdateFornecedor(true);
            setFlgFabricante(row.flg_fabricante);
            if (reg) {
              setFormProdutoFornecedor(reg);
            }
            return;
          }

          setSelectedCodFornecedor(row.cod_fornecedor);

          setTimeout(() => {
            handleSetFocus();
          }, 200);
        };

        const onDelete = async () => {
          if (row.flg_principal) {
            return toast.warning(
              'Atribua outro fornecedor preferencial antes de excluir o atual!',
            );
          }
          await MySwal.fire({
            text: 'Deseja realmente remover esse fornecedor?',
            showCancelButton: true,
            confirmButtonColor: '#07289e',
            cancelButtonColor: '#ff7b7b',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then((result) => {
            if (result.isConfirmed) {
              if (row.cod_fornecedor === selectedCodFornecedor) {
                setProdutoFornecedorUns([]);
                const { ref } = formProdutoFornecedor.cod_fornecedor;
                if (ref.current) {
                  ref.current.handleClearFornecedor();
                }
              }
              const filtered = formProduto.Fornecedores.fornecedores.filter(
                (item) => {
                  return (
                    Number(item.cod_fornecedor.value) !==
                    Number(row.cod_fornecedor)
                  );
                },
              );
              setFormProdutoFornecedor(formProdutoFornecedorBlank);
              setSelectedCodFornecedor(undefined);
              setValue('fornecedores', filtered);
              changeFormProduto({
                ...formProduto,
                Fornecedores: {
                  ...formProduto.Fornecedores,
                  fornecedores: filtered,
                },
              });
            }
          });
        };

        return (
          <AcoesContainer>
            <div className="d-flex align-items-center gap-2">
              <ButtonRow type="button" onClick={() => onEdit(true)}>
                <GoPackage size={20} style={{ color: '#979320' }} /> Embalagens
              </ButtonRow>
              <ButtonRow type="button" onClick={() => onEdit()}>
                <GoPencil size={20} style={{ color: '#72ab90' }} />
              </ButtonRow>
              <ButtonRow type="button" onClick={onDelete}>
                <MdDeleteForever size={20} style={{ color: '#e63c3c' }} />{' '}
              </ButtonRow>
            </div>
          </AcoesContainer>
        );
      },
    },
  ];

  function validaFormProdutoFornecedorUn(
    inputs: FormProdutoFornecedorUN,
  ): boolean {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formProdFornUN[Object.keys(inputs)[i] as keyof FormProdutoFornecedorUN]
          .isRequired &&
        formProdFornUN[Object.keys(inputs)[i] as keyof FormProdutoFornecedorUN]
          .isInvalid
      ) {
        return true;
      }
    }
    return false;
  }
  function handleAddProdFornUN() {
    if (validaFormProdutoFornecedorUn(formProdFornUN)) {
      setInitFormFornecedorUn(true);
      setError('des_referencia', { type: 'error' });
      return;
    }
    setInitFormFornecedorUn(false);

    const exists = produtoFornecedoresUns.some(
      (produto) =>
        produto.des_referencia === formProdFornUN.des_referencia.value &&
        produto.id !== formProdFornUN.id.value,
    );

    if (exists) {
      return toast.warning('Embalagem já adicionada com esses detalhes');
    }
    const fornecedores: any = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const fornecedorItem of formProduto.Fornecedores.fornecedores) {
      if (
        Number(formProdutoFornecedorEmbalagem.cod_fornecedor.value) ===
        Number(fornecedorItem.cod_fornecedor.value)
      ) {
        let unidades: any = [];
        if (fornecedorItem.Unidades.unidades.length !== 0) {
          const UnOld: any = [];
          // eslint-disable-next-line no-restricted-syntax
          for (let unFornecedor of fornecedorItem.Unidades.unidades) {
            if (unFornecedor.id.value === idProdUnid) {
              unFornecedor = formProdFornUN;
              UnOld.push(unFornecedor);
            } else {
              UnOld.push(unFornecedor);
            }
          }
          const indexUnidadeEdit = fornecedorItem.Unidades.unidades.findIndex(
            (un) => un.id.value === idProdUnid,
          );
          if (indexUnidadeEdit !== -1) {
            unidades = UnOld;
            produtoFornecedoresUns[indexUnidadeEdit].des_referencia =
              formProdFornUN.des_referencia.value;
            produtoFornecedoresUns[indexUnidadeEdit].des_unidade_compra =
              formProdFornUN.des_unidade_compra.value.value;
            produtoFornecedoresUns[indexUnidadeEdit].des_fornecedor =
              formProdutoFornecedorEmbalagem.des_fornecedor.value;
            produtoFornecedoresUns[indexUnidadeEdit].qtd_embalagem_compra =
              formProdFornUN.qtd_embalagem_compra.value;
            setProdutoFornecedorUns(produtoFornecedoresUns);
          } else {
            unidades = [...UnOld, formProdFornUN];
            setProdutoFornecedorUns([
              ...produtoFornecedoresUns,
              {
                id: nanoid(),
                des_referencia: formProdFornUN.des_referencia.value,
                des_unidade_compra:
                  formProdFornUN.des_unidade_compra.value.value,
                des_fornecedor:
                  formProdutoFornecedorEmbalagem.des_fornecedor.value,
                qtd_embalagem_compra: formProdFornUN.qtd_embalagem_compra.value,
              },
            ]);
          }
        } else {
          unidades.push(formProdFornUN);
          setProdutoFornecedorUns([
            ...produtoFornecedoresUns,
            {
              id: nanoid(),
              des_referencia: formProdFornUN.des_referencia.value,
              des_unidade_compra: formProdFornUN.des_unidade_compra.value.value,
              des_fornecedor:
                formProdutoFornecedorEmbalagem.des_fornecedor.value,
              qtd_embalagem_compra: formProdFornUN.qtd_embalagem_compra.value,
            },
          ]);
        }

        const fornecedoresData = {
          ...fornecedorItem,
          Unidades: {
            unidades,
          },
        };
        fornecedores.push(fornecedoresData);
      } else {
        fornecedores.push(fornecedorItem);
      }
    }

    setValue('fornecedores', fornecedores);

    changeFormProduto({
      ...formProduto,
      Fornecedores: {
        fornecedores,
        isInvalid: formProduto.Fornecedores.isInvalid,
        isRequired: formProduto.Fornecedores.isRequired,
      },
    });

    handleClearProdFornUN();
    handleSetFocus();
  }

  return (
    <>
      <Row>
        <Col sm={12} md={9} lg={6}>
          <BuscaParceiro
            isDisabled={disabledField}
            label="Fornecedor"
            placeholder="Informe a Descrição do Fornecedor"
            name="busca_parceiro"
            register={register}
            isError={!!errors.busca_parceiro}
            control={control}
            customOptions={{
              buscarPor: { cliente: false, fornecedor: true, loja: false },
            }}
            changeSelected={(selected: any) => {
              setValue('busca_parceiro', {
                label: selected.label,
                value: selected.value,
              });

              clearErrors('busca_parceiro');

              setFormProdutoFornecedor({
                ...formProdutoFornecedor,
                cod_fornecedor: {
                  ...formProdutoFornecedor.cod_fornecedor,
                  value: selected.cod_fornecedor,
                  isInvalid: selected === undefined,
                },
                num_cpf_cnpj: {
                  ...formProdutoFornecedor?.num_cpf_cnpj,
                  value: selected?.num_cpf_cnpj || '',
                  isInvalid: false,
                },
                des_fornecedor: {
                  ...formProdutoFornecedor.des_fornecedor,
                  value: selected.label,
                },
              });
            }}
          />
        </Col>

        <Col sm={12} md={3} lg={1}>
          <ToggleDefault
            labelText="Fabricante?"
            setChecked={flgFabricante}
            onSwitch={() => {
              setFormProdutoFornecedor({
                ...formProdutoFornecedor,
                flg_fabricante: {
                  ...formProdutoFornecedor.flg_fabricante,
                  value: !formProdutoFornecedor.flg_fabricante.value,
                },
              });
              setFlgFabricante(!formProdutoFornecedor.flg_fabricante.value);
            }}
          />
        </Col>

        <Col
          sm={12}
          md={12}
          lg={5}
          xl={5}
          className="d-flex align-self-end justify-content-end mt-2"
        >
          <ConfirmButton onClick={handleAddProdutoFornecedor}>
            Adicionar
          </ConfirmButton>
          <ClearButton
            onClick={() => {
              setUpdateFornecedor(false);
              handleClearProdutoFornecedor();
            }}
          >
            Limpar
          </ClearButton>
        </Col>
        <Col sm={12} md={12} lg={12} xl={12}>
          <TableContainer>
            <DataGrid
              rows={produtoFornecedores}
              columns={produtoFornecedorColumns}
              selectionModel={formProdutoFornecedor.cod_fornecedor.value}
              hideFooterSelectedRowCount
              disableColumnFilter
              disableColumnSelector
              hideFooterPagination
              localeText={{
                noRowsLabel: 'Nenhum registro encontrado',
                columnMenuLabel: 'Menu',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Esconder',
                columnMenuUnsort: 'Não ordenar',
                columnMenuSortAsc: 'Ordernar ASC',
                columnMenuSortDesc: 'Ordernar DESC',
                columnMenuShowColumns: 'Mostrar columnas',
              }}
            />
          </TableContainer>
        </Col>
      </Row>
      {selectedCodFornecedor !== undefined && (
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Separator
              labelText={`Unidade/Embalagem - ${formProdutoFornecedorEmbalagem.des_fornecedor.value}`}
            />
          </Col>

          <Col sm={12} md={7} lg={5} xl={4}>
            <InputText
              maxLength={35}
              label="Referência"
              placeholder="Digite aqui a Referência"
              name="des_referencia"
              id="des_referencia"
              register={register}
              isError={!!errors.des_referencia}
              onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                const { value } = ev.target;
                setValue('des_referencia', value.toLowerCase().trim());
                clearErrors('des_referencia');
                setFormProdFornUN({
                  ...formProdFornUN,
                  des_referencia: {
                    ...formProdFornUN.des_referencia,
                    value: value.toLowerCase().trim(),
                    isInvalid: value.trim() === '',
                  },
                });
              }}
            />
          </Col>

          <Col sm={12} md={3} lg={2} xl={2}>
            <InputSelect
              options={unidadesOptions as SelectType[]}
              label="Compra"
              name="des_unidade_compra_fornecedor"
              register={register}
              control={control}
              isError={!!errors.des_unidade_compra_fornecedor}
              changeSelected={(selected: any) => {
                setValue('des_unidade_compra_fornecedor', selected);

                setFormProdFornUN({
                  ...formProdFornUN,
                  des_unidade_compra: {
                    ...formProdFornUN.des_unidade_compra,
                    value: selected,
                  },
                });
              }}
            />
          </Col>

          <Col sm={12} md={2} lg={2} xl={1}>
            <InputNumber
              label="C/"
              min={1}
              max={99999}
              maxLength={5}
              placeholder=""
              name="qtd_embalagem_compra_fornecedor"
              register={register}
              isError={
                !!errors.qtd_embalagem_compra_fornecedor ||
                formProdFornUN.qtd_embalagem_compra.isInvalid
              }
              onInput={(ev: any) => {
                const value = parseFloat(ev.target.value.slice(0, 5));

                setValue('qtd_embalagem_compra_fornecedor', value);

                if (value <= 99999) {
                  setFormProdFornUN({
                    ...formProdFornUN,
                    qtd_embalagem_compra: {
                      ...formProdFornUN.qtd_embalagem_compra,
                      value,
                      isInvalid: value < 1,
                    },
                  });
                }
              }}
            />
          </Col>

          <Col
            sm={12}
            md={12}
            lg={12}
            xl={5}
            className="d-flex align-self-end justify-content-end mt-2"
          >
            <ConfirmButton onClick={handleAddProdFornUN}>
              Adicionar
            </ConfirmButton>
            <ClearButton onClick={() => handleClearProdFornUN()}>
              Limpar
            </ClearButton>
          </Col>

          <Col sm={12} md={12}>
            <TableContainer>
              <DataGrid
                rows={produtoFornecedoresUns}
                columns={unidadeColumns}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                disableColumnMenu
                pageSize={5}
                localeText={{
                  noRowsLabel: 'Nenhum registro encontrado',
                  columnMenuLabel: 'Menu',
                  columnMenuFilter: 'Filtrar',
                  columnMenuHideColumn: 'Esconder',
                  columnMenuUnsort: 'Não ordenar',
                  columnMenuSortAsc: 'Ordernar ASC',
                  columnMenuSortDesc: 'Ordernar DESC',
                  columnMenuShowColumns: 'Mostrar columnas',
                }}
              />
            </TableContainer>
          </Col>
        </Row>
      )}
      {/* </Row> */}
    </>
  );
};
export default AbaFornecedor;
