import { formAssociadoBlank } from '~/pages/Produto/protocols';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ProdutoContext } from '../../ProdutoContext';
import { Form } from './Form';
import { ButtonNavigation, ButtonSeparator } from './styles';
import { TableAssociado } from './TableAssociado';
import { InputAsyncSelect } from '~/components/NovosInputs';
import Separator from '~/components/Separator';

const AbaEquivalentes: React.FC = () => {
  const {
    formProduto,
    changeFormProduto,
    formProdutoLoja,
    changeFormProdutoLoja,
    setValue,
    register,
    control,
    formState: { errors },
    changeFormAssociado,
    formAssociado,
    resetSimilar,
    setResetSimilar,
  } = useContext(ProdutoContext);

  function handleChangeAssoc(value: number) {
    if (value === 2) {
      setValue('per_desc_assoc', '');
    }
    setValue('tipo_associado', value);

    setValue('per_desc_assoc', '');
    setValue('per_acresc_assoc', '');
    setValue('editAssociado', { value: undefined, label: '' });

    const { ref } = formAssociado.cod_associado;

    if (ref.current) {
      ref.current.handleClearProduto();
    }

    changeFormAssociado(formAssociadoBlank);
    changeFormProdutoLoja({
      ...formProdutoLoja,
      tipo_associado: {
        ...formProdutoLoja.tipo_associado,
        value,
      },
    });
  }

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <InputAsyncSelect
            label="Similares (Preço)"
            maxLength={50}
            placeholder="Nenhum Similar Selecionado"
            name="cod_similar"
            register={register}
            isError={!!errors.cod_similar}
            control={control}
            includeNoneOption
            changeSelected={(selected: any) => {
              selected.value = selected.cod_similar || selected.value;
              setValue('cod_similar', {
                value: selected.value,
                label: selected.label,
              });

              changeFormProduto({
                ...formProduto,
                cod_similar: {
                  ...formProduto.cod_similar,
                  value: selected.value,
                },
              });
            }}
            api={{
              route: '/produto/similar',
              method: 'get',
              fields: ['des_similar'],
              searchBeforeFilter: true,
            }}
          />
        </Col>
        <Col sm={12} md={12} lg={12} xl={12}>
          <InputAsyncSelect
            label="Marca"
            maxLength={50}
            placeholder="Nenhuma Marca Selecionada"
            name="cod_marca"
            register={register}
            isError={!!errors.cod_marca}
            control={control}
            includeNoneOption
            changeSelected={(selected: any) => {
              selected.value = selected.cod_marca || selected.value;
              setValue('cod_marca', {
                value: selected.value,
                label: selected.label,
              });

              changeFormProduto({
                ...formProduto,
                cod_marca: {
                  ...formProduto.cod_marca,
                  value: selected.value,
                },
              });
            }}
            api={{
              route: '/produto/marcas',
              method: 'get',
              fields: ['des_marca'],
              searchBeforeFilter: true,
            }}
          />
        </Col>
      </Row>

      <Row className="mt-4 mb-4">
        <Col md={7} sm={12}>
          <Separator labelText="Associado (Estoque)" childrenWidth="160px" />
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          md={5}
          sm={12}
        >
          <ButtonNavigation
            type="button"
            className="btnNewDataGrid"
            style={{
              backgroundColor:
                formProdutoLoja.tipo_associado.value === 0
                  ? '#8850BF'
                  : 'transparent',
              color:
                formProdutoLoja.tipo_associado.value === 0 ? '#fff' : '#6B778C',
            }}
            onClick={() => handleChangeAssoc(0)}
          >
            Independente
          </ButtonNavigation>

          <ButtonSeparator />

          <ButtonNavigation
            type="button"
            className="btnNewDataGrid"
            onClick={() => handleChangeAssoc(1)}
            style={{
              padding: '5px 10px',
              backgroundColor:
                formProdutoLoja.tipo_associado.value === 1
                  ? '#8850BF'
                  : 'transparent',
              color:
                formProdutoLoja.tipo_associado.value === 1 ? '#fff' : '#6B778C',
            }}
          >
            Incluir Pai
          </ButtonNavigation>

          <ButtonSeparator />

          <ButtonNavigation
            type="button"
            className="btnNewDataGrid"
            style={{
              backgroundColor:
                formProdutoLoja.tipo_associado.value === 2
                  ? '#8850BF'
                  : 'transparent',
              color:
                formProdutoLoja.tipo_associado.value === 2 ? '#fff' : '#6B778C',
            }}
            onClick={() => handleChangeAssoc(2)}
          >
            Incluir Filhos
          </ButtonNavigation>
        </Col>
      </Row>

      {formProdutoLoja.tipo_associado.value !== 0 && (
        <>
          <Form />
          <TableAssociado />
        </>
      )}
    </>
  );
};
export default AbaEquivalentes;
